import React from "react";
import { Row, Col, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { emailConfirmationSchema } from "../../../validationSchemas/loginSchema";
import Button from "../../Buttons/Button";
import { FormField } from "../../FormFields/FormField";


export default function ValidateEmailModal(props){
    const {
      showValidateEmail,
      setShowValidateEmail,
      initialFormStateForEmailVarification,
      handleEmailVerificationFormSubmission,
      isItMobile,
      addDarkerOverlay=false
    } = props;
    const closeModal = () => {
      setShowValidateEmail(false);
    };
    return (
      <Modal
        show={showValidateEmail}
        centered
        className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
        onHide={closeModal}
        style={addDarkerOverlay ? {background: "#00000088"} : {}}
        size="sm"
      >
        <Modal.Header className="x-4 justify-content-center">
          <button
            type="button"
            className="btn modal-close-button pe-0 sm-blue-icon"
            onClick={closeModal}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body className="px-3">
          <>
            <h4 className="f-s22 font-Cls text-center fw-normal lh-normal mb-3 text-capitalize">
              Please Enter Your Email Address to Continue
            </h4>
            <div
              className="new_form_design"
              style={isItMobile ? {} : { marginInline: '2rem' }}
            >
              <Formik
                initialValues={initialFormStateForEmailVarification}
                validationSchema={emailConfirmationSchema}
                onSubmit={handleEmailVerificationFormSubmission}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                  isSubmitting,
                  submitForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <FormField
                          labelText={'EMAIL'}
                          name="email"
                          type="email"
                          placeholder="Email"
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          title=""
                        />
                      </Col>
                      <Col md="12">
                        <FormField
                          labelText={'CONFIRM EMAIL'}
                          name="confirm_email"
                          type="email"
                          placeholder="Confirm Email"
                          onChange={handleChange}
                          errors={errors}
                          touched={touched}
                          title=""
                        />
                      </Col>
                    </Row>
                    <Button
                      type="button"
                      className="btn btn-large mb-sm-4 mb-3 mt-3 d-block w-100"
                      onClick={submitForm}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Continue
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </>
        </Modal.Body>
      </Modal>
    );
  };
