import React, { useState } from "react";
import * as CONSTANT from "../../../constants";
interface IItemModifiers {
  modifiers: any[];
  paragraphClassName?: string;
}

const ItemModifiers: React.FC<IItemModifiers> = ({
  modifiers,
  paragraphClassName,
}) => {
  const [showMore, setShowMore] = useState(false);
  if (modifiers.length === 0) return null;

  const handleModifierCode = (code: string, modifier: any) => {
    const quantity = modifier.quantity > 1 ? `(${modifier.quantity})` : ''
    switch (code) {
      case CONSTANT.ADD:
        return <div className="d-flex justify-content-between w-100" data-testid="itemModifierNameDiv">
          <span className="ms-0  clr-dark-blue d-block" data-testid="itemModifierName">{`+ ${modifier?.modifier_name} `}<span>{`${quantity}`}</span></span>
          {modifier?.total_price > 0 && <span className="ms-0  clr-dark-blue d-block" data-testid="itemModifierPrice"> {`$${modifier?.total_price.toFixed(2)}`} </span>}
        </div>
      case CONSTANT.NO:
        return <>
          <span className="ms-0  clr-dark-blue d-block">{`- ${modifier?.modifier_name} ${quantity}`}</span>
        </>;
      case CONSTANT.SUB:
        return (
          <div className="d-flex justify-content-between w-100" >
            <div>
              <span className="ms-0  clr-dark-blue d-block">{`+ ${modifier?.substituted_modifier?.modifier_name}`}</span>
              <span className="ms-0  clr-dark-blue d-block">{`-${modifier?.modifier_name}`}</span>
            </div>
            {modifier?.total_price > 0 && <span className="ms-0  clr-dark-blue d-block"> {`$${modifier?.total_price.toFixed(2)}`} </span>}
          </div>
        );
      default:
        return null;
    }
  };

  const handleShowMoreOrLess = () => {
    if (showMore) setShowMore(false);
    else setShowMore(true);
  };

  const sliceTill = showMore ? modifiers.length : 1;

  return (
    modifiers.length > 0 && (
      <p className={paragraphClassName ? paragraphClassName : ""} data-testid="AllItemModifiers">
        {modifiers.slice(0, sliceTill).map((modifier: any, index: number) => {
          return (
            <span key={index}>
              {handleModifierCode(modifier.code, modifier)}
              {/* <br /> */}
            </span>
          );
        })}
        {modifiers.length > 1 && (
          <span
            className="cursor-pointer clr-sb-dark"
            onClick={handleShowMoreOrLess}
            data-testid="peekAllModifiers"
          >
            {!showMore ? "see more" : "see less"}
          </span>
        )}
      </p>
    )
  );
};

export default ItemModifiers;
