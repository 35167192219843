import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import React, {useEffect, useState} from "react";
import AppleLogin from "react-apple-login";
import { useDispatch } from "react-redux";
import {
  FACEBOOK_PROFILE_INFO,
  GOOGLE_PROFILE_INFO, RESEND_EMAIL_VERIFICATION, ROUTE_CHECKOUT,
} from "../../../../constants";
import {
  setLocation,
  useStoreLocation,
} from "../../../../context/StoreLocation";
import {
  useAuthState,
  socialSignIn,
} from "../../../../context/UserAuthentication";
import { Toast_Func } from "../../../../helpers/toast.helper";
import {setShowCart} from "../../../../redux/slices/cartSlice";
import {useHistory} from "react-router";
import * as CONSTANT from "../../../../constants";
import {brazeLogCustomEventLoyaltyAccountCreated, brazeLogCustomEventLoyaltySignIn, brazeSetUser} from "../../../../helpers/brazeHelper";
import {getUserId, getVisitorId} from "../../../../helpers/helperMethods";
import useCheckMobileScreen from "../../../../hooks/useCheckMobileScreen";
import Spinner from "../../../Spinner/Spinner";
import styles from "../../CartItems/CartItems.module.scss";
import ValidateEmailModal from "../../../Molecules/Modal/ValidateEmailModal";
import ResendEmailModal from "../../../Molecules/Modal/ResendEmailModal";
import { LoginFormFields } from "../../../../models/forms.model";

const SocailLogin = () => {
  const { authInfo, dispatch } = useAuthState();
  const [isDeviceIos, setIsDeviceIos] = useState<boolean>(false);
  const { dispatch: locationDispatch } = useStoreLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sendingEmail, setSendingEmail] = React.useState<boolean>(false);
    const [showValidateEmail, setShowValidateEmail] = useState<boolean>(false);
    const [resendEmail, setResendEmail] = useState<boolean>(false);
    const [resendEmailBtnText, setResendEmailBtnText] = useState<string>(
      RESEND_EMAIL_VERIFICATION
    );
    const [resendEmailData, setResendEmailData] = useState<{
      "is_email_sent": boolean,
      "email": string,
      "header":string,
      "body1": string,
      "body2": string
  }>();
    const [resendEmailPayload, setResendEmailPayload] = useState<any>({});
    const initialFormStateForEmailVarification = {
      email: '',
      confirm_email: '',
    };
    const cartDispatch = useDispatch();
  const isItMobile = useCheckMobileScreen();
  const history  = useHistory();

  const signInWithFacebook = () => {
    FB.login(
      function (response) {
        if (response.status === "connected") {
          let accessToken = response.authResponse.accessToken;
          let user_url = `${FACEBOOK_PROFILE_INFO}${accessToken}`;
          axios
            .get(user_url)
            .then(async (response) => {
              let payload = {
                first_name: response?.data?.first_name,
                last_name: response?.data?.last_name,
                email: response?.data?.email,
                fb_uid: response?.data?.id,
                access_token: accessToken,
              };
              setIsLoading(true)
              await socialSignIn(
                dispatch,
                payload,
                "FACEBOOK_SIGNIN",
                cartDispatch,
                (selectedStore) => {
                  setLocation(locationDispatch, selectedStore);
                },
                true
              );
              cartDispatch(setShowCart(false));
            })
            .catch((error) => {
              setIsLoading(false)
              Toast_Func({
                status: false,
                message: "Something went wrong. Please try again",
              });
            });
        }
      },
      {
        enable_profile_selector: true,
        scope: "email,public_profile",
        return_scopes: true,
      }
    );
  };

  const signInWithGoogle = useGoogleLogin({
    scope: "profile email ",
    prompt: "consent",
    onSuccess: (tokenResponse) => {
      const instance = axios.create();
      delete instance.defaults.headers.common["Authorization"];
      instance
        .get(`${GOOGLE_PROFILE_INFO}${tokenResponse.access_token}`)
        .then( async (response) => {
          let payload = {
            access_token: tokenResponse.access_token,
            user: {
              first_name: response.data.given_name,
              last_name: response.data.family_name,
              email: response.data.email,
            },
          };
          setIsLoading(true)
          await socialSignIn(
            dispatch,
            payload,
            "GOOGLE_SIGNIN",
            cartDispatch,
            (selectedStore) => {
              setLocation(locationDispatch, selectedStore);
            },
              true
          );
          cartDispatch(setShowCart(false));
        })
        .catch((error) => {
          setIsLoading(false)
          Toast_Func({
            status: false,
            message: "Something went wrong. Please try again",
          });
        });
    },
  });

    const signInUserWithoutNewAPICall = (response: any): void => {
      dispatch({ type: 'LOGIN_SUCCESS', payload: response.data.data });
      localStorage.setItem(
        CONSTANT.CURRENT_USER,
        JSON.stringify({ ...response.data.data, visitor_id: getVisitorId() })
      );
      if (response.data.data.is_new_user)
        brazeLogCustomEventLoyaltyAccountCreated(response.data.data);
      cartDispatch(setShowCart(false));
    };

    const handleEmailVerificationFormSubmission = async (
      values: LoginFormFields,
      { setSubmitting }: any
    ) => {
      let payload: any = {
        ...resendEmailPayload,
        email: values.email,
        visitor_id: getVisitorId(),
        apple_verified: false,
        source: isItMobile ? 'Mobile Web' : 'Web',
      };
      setResendEmailPayload(payload);
      let response = await axios.post(
        `${CONSTANT.CUSTOMER_MICRO_SERVICE}${CONSTANT.APPLE_SIGNIN}`,
        payload
      );
      if (!response?.data?.data?.is_email_sent) {
        signInUserWithoutNewAPICall(response);
      } else if (response.data) {
        setResendEmailData(response?.data?.data);
        setShowValidateEmail(false);
        setResendEmail(true);
      }
    };

  const appleSignInAPI = async (data) => {
    try {
      let payload = {
        is_user_exists: true,
        visitor_id: getVisitorId(),
        ...data,
      };
      let response = await axios.post(
        `${CONSTANT.CUSTOMER_MICRO_SERVICE}${CONSTANT.APPLE_SIGNIN}`,
        payload
      );
      if (
        !response?.data?.data?.is_user_exists &&
        response.data?.data.hasOwnProperty('is_user_exists')
      ) {
        setResendEmailPayload(data);
        setShowValidateEmail(true);
      } else if (!response.data?.data.hasOwnProperty('is_user_exists')) {
        signInUserWithoutNewAPICall(response);
      } else {
        payload = {
          is_user_exists: false,
          apple_verified: true,
          ...data,
        };
        await socialSignIn(
          dispatch,
          payload,
          'APPLE_SIGNIN',
          cartDispatch,
          (selectedStore) => {
            setLocation(locationDispatch, selectedStore);
          },
          true
        );
       cartDispatch(setShowCart(false));
      }
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      Toast_Func({ status: false, message: 'Please try again' });
    }
  };

  const signInWithApple = async (response) => {
    try {
      let payload = {
        id_token: response?.authorization?.id_token,
        code: response?.authorization?.code,
        first_name: response?.user?.name?.firstName || null,
        last_name: response?.user?.name?.lastName || null,
      };
      setIsLoading(true);
      appleSignInAPI(payload);
    } catch (error) {
      setIsLoading(false)
      Toast_Func({ status: false, message: "Please try again" });
    }
  };
  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
      setIsDeviceIos(true);
    } else {
      setIsDeviceIos(false);
    }
  }, []);
  useEffect(() => {
    if (authInfo.userId && authInfo.type !== CONSTANT.GUEST_USER) {
      setIsLoading(false)
      history.push(ROUTE_CHECKOUT);

      brazeSetUser(authInfo.userId)
      brazeLogCustomEventLoyaltySignIn(isItMobile)
    }
  }, [authInfo])
  return isLoading ? (
      <Spinner className={styles.cart_spinner} showFooter={"d-none"} />
  ) : (
    <>
      <ul className="account-social-icons pt-4">
        {isDeviceIos ? (
          <>
            <li>
              <AppleLogin
                clientId={process.env.REACT_APP_APPLE_SIGNIN_CLIENT_ID}
                scope="name email"
                redirectURI={process.env.REACT_APP_APPLE_SIGNIN_REDIRECT}
                usePopup={true}
                callback={signInWithApple}
                render={(
                  renderProps //Custom Apple Sign in Button
                ) => (
                  <button type="button" onClick={renderProps.onClick} disabled={isLoading}>
                    <img
                      src={require("../../../../images/Apple.svg")}
                      alt="apple"
                    />
                  </button>
                )}
              />
            </li>
            <li>
              <button disabled={isLoading} type="button" onClick={signInWithGoogle as () => void}>
                <img
                  src={require("../../../../images/Google_Logo.svg")}
                  alt="google logo"
                />
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <button disabled={isLoading} type="button" onClick={signInWithGoogle as () => void}>
                <img
                  src={require("../../../../images/Google_Logo.svg")}
                  alt="google"
                />
              </button>
            </li>
          </>
        )}
        <li>
          <button disabled={isLoading} type="button" onClick={signInWithFacebook}>
            <img
              src={require("../../../../images/Facebook.svg")}
              alt="facebook"
            />
          </button>
        </li>
      </ul>
      {showValidateEmail &&
        !resendEmail &&
        <ValidateEmailModal
          showValidateEmail={showValidateEmail}
          setShowValidateEmail={setShowValidateEmail}
          initialFormStateForEmailVarification={initialFormStateForEmailVarification}
          handleEmailVerificationFormSubmission={handleEmailVerificationFormSubmission}
          isItMobile={isItMobile}
          addDarkerOverlay={true}
        />
        }
      {!showValidateEmail &&
        resendEmail &&
        <ResendEmailModal
          resendEmail={resendEmail}
          setResendEmail={setResendEmail}
          setShowValidateEmail={setShowValidateEmail}
          resendEmailPayload={resendEmailPayload}
          sendingEmail={sendingEmail}
          setSendingEmail={setSendingEmail}
          setResendEmailData={setResendEmailData}
          resendEmailData={resendEmailData}
          isItMobile={isItMobile}
          setResendEmailBtnText={setResendEmailBtnText}
          resendEmailBtnText={resendEmailBtnText}
          signInUserWithoutNewAPICall={signInUserWithoutNewAPICall}
          addDarkerOverlay={true}
        />
        }
    </>
  );
};

export default SocailLogin;
