import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Route, RouteProps, useLocation } from "react-router";
import { REACT_APP_DOMAIN } from "../../constants";
import { META_TAGS } from "./MetaTags";
import { HandleFallBackUrlsContext } from '../../context/HandleFallBackUrlsContext';
import Spinner from "../Spinner/Spinner";

interface IReactHelmetWrapper {
  path: string;
  exact?: boolean;
  routeComponent?:
    | React.LazyExoticComponent<React.ComponentType<any>>
    | React.ComponentType<any>;
}

const ReactHelmetWrapper: React.FunctionComponent<IReactHelmetWrapper> = ({
  routeComponent: RouteComponent,
  exact,
  path,
}) => {
  const { pathname, search } = useLocation();
  const redirectPath = `${pathname}${search}`
  const handleFallBackUrlsContext = useContext(HandleFallBackUrlsContext);

  const { handleFallBackUrlsLoading } = handleFallBackUrlsContext;

  if (handleFallBackUrlsLoading) {
    return <Spinner lightBg={true} /> // Placeholder for loading state
  }

  const renderComponentWithHelmet = (routeProps: RouteProps) => {
    return (
      <>
        <Helmet>
          <link
            rel="canonical"
            href={`${REACT_APP_DOMAIN}${pathname ? pathname : ""}`}
          />
          {META_TAGS.hasOwnProperty(pathname) &&
            META_TAGS[pathname].length > 0 &&
            META_TAGS[pathname]}
        </Helmet>
        <RouteComponent {...routeProps} />
      </>
    );
  };

  return (
    <Route
      path={path}
      exact={exact ? exact : true}
      render={renderComponentWithHelmet}
    ></Route>
  );
};

export default ReactHelmetWrapper;
