import React, { useState } from "react";
import { Formik } from "formik";
import { FormField } from "../FormFields/FormField";
import {
  IRedeemPointsByOrderId,
  IUseCoupon,
} from "../../models/order.model";
import { Modal } from "react-bootstrap";
import useCoupon from "../../react-query-hooks/Cart/useCoupon";
import { Toast_Func } from "../../helpers/toast.helper";
import useRedeemPointsByOrderId from "../../react-query-hooks/useRedeemPointsByOrderId";
import Button from "../Buttons/Button";
import { useHistory } from "react-router";
import { REACT_APP_CLOUD_FRONT, ROUTE_TALK_TO_US } from "../../constants";
import ScanQRCode from "./ScanQRCode";
import { getUser } from "../../helpers/helperMethods";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { useStoreLocation } from "../../context/StoreLocation";


interface ILiveTrackingProps {
  showModal: boolean;
  handleBackModal: (clickedOutSideModal?: boolean) => void;
  closeModalOnSuccess: () => void;
}

const ScanModal: React.FC<ILiveTrackingProps> = ({
  showModal,
  handleBackModal,
  closeModalOnSuccess,
}) => {
  const history = useHistory();
  const user = getUser();
  const isItMobile = useCheckMobileScreen();
  const { locationInfo } = useStoreLocation();
  const location_id = locationInfo.selectedStore?.id

  const [isPromoCodeSelected, setIsPromoCodeSelected] =
    useState<boolean>(false);
  const [isAddPointsSelected, setIsAddPointsSelected] =
    useState<boolean>(false);

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const isEitherSelected = isPromoCodeSelected || isAddPointsSelected;
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const [apiSuccessMessage, setApiSuccessMessage] = useState<string>("");

  const { mutate: applyCoupon, isLoading: isApplyingCoupon } = useCoupon();
  const {
    mutate: redeemPointsByOrderId,
    isLoading: isRedeemingPointsByOrderId,
  } = useRedeemPointsByOrderId();

  const initialFormState = {
    code: "",
  };

  const resetModalStates = () => {
    setIsPromoCodeSelected(false);
    setIsAddPointsSelected(false);
    setApiErrorMessage("");
  }

  const backModalHandler = () => {
    if (isEitherSelected) {
      resetModalStates()
      return;
    }
    handleBackModal();
  };

  const handleClickedOutSideModal = () => {
    resetModalStates()
    handleBackModal(true);
  };

  const handleApplyCoupon = async (code: string) => {
    const payload: IUseCoupon = {
      promo_code: code,
      location_id,
      source: isItMobile ? 'Mobile Web' : 'Web',
    };
    applyCoupon(payload, {
      onSuccess: async (res: any) => {
        closeModalOnSuccess();
        resetModalStates()
        Toast_Func({
          status: true,
          message: "Your Offer has been successfully added in your cart.",
        });
      },
      onError: (error: any) => {
        setApiErrorMessage(error.response.data?.errors[0]);
      },
    });
  };

  const handleRedeemPointsByOrderId = async (
    code: number,
  ) => {
    const payload: IRedeemPointsByOrderId = {
      order_id: code,
    };
    redeemPointsByOrderId(payload, {
      onSuccess: async (res: any) => {
        closeModalOnSuccess();
        resetModalStates();
        setApiSuccessMessage(res.data?.message);
        setShowSuccessModal(true);
      },
      onError: (error: any) => {
        setApiErrorMessage(error.response.data?.errors[0]);
      },
    });
  };

  const handleFormSubmission = (values) => {
    if (isPromoCodeSelected) handleApplyCoupon(values.code);
    else if (isAddPointsSelected)
      handleRedeemPointsByOrderId(values.code);
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClickedOutSideModal}
        centered
        className="theme-modal-style app_container_wrap no-padding border-radius-15 no-border  responsive-100vmax receipt-modal-scan"
      >
        <Modal.Header
          className="pt-3 pt-md-3 mt-md-2 ps-0 pb-2"
          id="close_button2"
        >
          <button
            type="button"
            className="btn btn-custom back-arrow-btn f-s14 px-0 mb-0"
            onClick={backModalHandler}
          >
            Back
          </button>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <>
            {!isEitherSelected ? (
              <>
                {isItMobile ? <ScanQRCode valueCode={user.loyalty_user_id} /> : null}
                <div className="text-center py-md-5 py-2 d-flex align-items-center justify-content-center flex-column gap-3">
                  <button
                    className="btn btn-large w-50"
                    onClick={() => setIsAddPointsSelected(true)}
                  >
                    ADD RECEIPT CODE
                  </button>
                  <button
                    type="button"
                    className="clr-dark-blue btn btn-custom p-0 f-w6 p-16"
                    onClick={() => setIsPromoCodeSelected(true)}
                  >
                    Have a Promo Code?
                  </button>
                </div>
              </>
            ) : (
              <div>
                <h5 className="f-s22 font-Cls text-center fw-normal pb-1 text-capitalize">
                  {apiErrorMessage ? "Uh Oh..." :
                    (isPromoCodeSelected ? "Get Offer By Code" : "Add Points")
                  }
                </h5>
                <p className={`font-rale text-center f-w5 f-s14 ${apiErrorMessage && 'clr-dark-red'}`}> {
                  apiErrorMessage ? apiErrorMessage :
                    isAddPointsSelected && 'Enter the Order ID found at the bottom of your receipt.'
                }</p>
                {
                  isAddPointsSelected && <p className="font-rale text-center f-w5 f-s14 "> You can only receive points for orders placed in the last 30 days.</p>
                }
                <Formik
                  initialValues={initialFormState}
                  onSubmit={handleFormSubmission}
                  validationSchema={''}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleSubmit,
                    setFieldValue,
                    handleChange,
                  }) => (
                    <form
                      className="full_width_mob new_form_design  w-100 my-0 mx-auto"
                      onSubmit={handleSubmit}
                    >
                      <FormField
                        name="code"
                        placeholder={
                          isPromoCodeSelected
                            ? "Enter Promo Code"
                            : "Enter Order Id"
                        }
                        type={isPromoCodeSelected ? "text" : "number"}
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        inputFieldClass={`text-center f-s20 ${isPromoCodeSelected ? 'my-2' : 'mt-2 mb-4'}`}
                      />
                      {apiErrorMessage && (
                        <div className="mt-4 mb-5">
                          <p className="font-rale text-center f-w5 f-s14 mb-0 pt-4">
                            Tried multiple times?
                          </p>
                          <div className="text-center py-1  d-flex align-items-center justify-content-center flex-column">
                            <button
                              type="button"
                              className="clr-dark-blue btn btn-custom p-0 f-w6 p-16"
                              onClick={() => history.push(ROUTE_TALK_TO_US)}
                            >
                              Contact Us
                            </button>
                          </div>
                        </div>
                      )}
                      {!apiErrorMessage && isPromoCodeSelected &&
                        <p className="font-rale text-center f-w5 f-s14 mb-0 py-4">
                          Your Offer will appear in the "Offers" section in your cart. This code will no longer be usable after being added.
                        </p>
                      }
                      <Button
                        type="button"
                        className="btn btn-large py-1 lh-lg f-s16 w-100 mb-3"
                        disabled={
                          !values.code ||
                          isApplyingCoupon ||
                          isRedeemingPointsByOrderId
                        }
                        loading={isApplyingCoupon || isRedeemingPointsByOrderId}
                        onClick={handleSubmit}
                      >
                        {apiErrorMessage ? "Try Again" :
                          (isPromoCodeSelected ? "Claim Offer" : "Add Points")
                        }
                      </Button>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccessModal}
        className="theme-modal-style app_container_wrap no-padding border-radius-15 no-border  responsive-100vmax receipt-modal-scan"
        centered>
        <Modal.Header
          className="pt-3 pt-md-3 mt-md-2 ps-0 pb-2"
        >
          <button
            type="button"
            className="btn modal-close-button pe-0"
            onClick={() => { setShowSuccessModal(false) }}
          >
            Close
          </button>
          <h5 className="f-s22 font-Cls text-center fw-normal py-3 mb-0 w-100 text-capitalize">Thanks!</h5>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div>
            <img src={`${REACT_APP_CLOUD_FRONT}/images/gold-coins-pile.png`} alt="coins-image"
              className="img-fluid" />
            <div className="mt-3">
              <p className="font-rale text-center f-w5 f-s14 mb-1">{apiSuccessMessage}</p>
              <p className="font-rale text-center f-w5 f-s14 ">It could take up to 24 hours for the points to show in your account.</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>



  );
};

export default ScanModal;
