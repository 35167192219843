import React, { createContext, useState, useEffect, ReactNode } from 'react';
import useHandleFallBackUrls from '../hooks/useHandleFallBackUrls';
interface HandleFallBackUrlsContextType {
  handleFallBackUrlsData: any;
  handleFallBackUrlsLoading: boolean;
}

export const HandleFallBackUrlsContext = createContext<HandleFallBackUrlsContextType | undefined>(undefined);

interface HandleFallBackUrlsProviderProps {
  children: ReactNode;
}

export const HandleFallBackUrlsProvider = ({ children }: HandleFallBackUrlsProviderProps) => {
  const [handleFallBackUrlsLoading, setHandleFallBackUrlsLoading] = useState(true);
  const { data: handleFallBackUrlsData, loading } = useHandleFallBackUrls();

  useEffect(() => {
    setHandleFallBackUrlsLoading(loading);
  }, [loading]);

  return (
    <HandleFallBackUrlsContext.Provider value={{ handleFallBackUrlsData, handleFallBackUrlsLoading }}>
      {children}
    </HandleFallBackUrlsContext.Provider>
  );
};
