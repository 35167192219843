import React, { useContext } from "react";
import { Route, Redirect, useLocation, RouteProps } from "react-router";
import { ROUTE_LOGIN } from "../../constants";
import { useAuthState } from "../../context/UserAuthentication";
import { HandleFallBackUrlsContext } from '../../context/HandleFallBackUrlsContext';
import Spinner from "../Spinner/Spinner";

interface PrivateRouteProps extends RouteProps {
  component: any;
}

const RequireAuth: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...restOfProps
}) => {
  const { authInfo } = useAuthState();
  const { location } = restOfProps;

  const handleFallBackUrlsContext = useContext(HandleFallBackUrlsContext);

  const { handleFallBackUrlsLoading } = handleFallBackUrlsContext;

  if (handleFallBackUrlsLoading) {
    return <Spinner lightBg={true} /> // Placeholder for loading state
  }
  
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        authInfo.authentication_token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_LOGIN,
              state: { referrer: location.pathname },
            }}
          />
        )
      }
    />
  );
};

export default RequireAuth;
