import React, { useState } from "react";
import Button from "../Buttons/Button";
import {useAuthState} from "../../context/UserAuthentication";
import InputCheckbox from '../FormFields/Checkbox/InputCheckbox';
import {useQueryClient} from "react-query";
import {GUEST_USER} from "../../constants";
import {Toast_Func} from "../../helpers/toast.helper";
import {Formik} from "formik";
import {FormField} from "../FormFields/FormField";
import useAddGiftCard from "../../react-query-hooks/useAddGiftCard";
import {giftCardSchema} from "../../validationSchemas/giftCardSchema";
import useUpdateGiftCard from "../../react-query-hooks/useUpdateGiftCard";
import {IGiftCard} from "../../models/giftCard.model";
import {isGuestUser} from "../../helpers/helperMethods";
import {useAppSelector} from "../../redux/store/store";
import selectedGiftCard from "../../containers/order/PaymentMethod/SelectedGiftCard";

interface IGiftCardForm {
    isCheckout?: boolean,
    handleCloseGiftCard?: () => void,
    editGiftCard?: IGiftCard,
    handleCheckoutNewPaymentSuccess?:  (data, is_saved) => void;
}

const GiftCardForm = (props: IGiftCardForm) => {
    const {
        isCheckout, handleCloseGiftCard, handleCheckoutNewPaymentSuccess, editGiftCard
    } = props
    const initialFormState = {
        name: editGiftCard?.name || "",
        gift_card_no: editGiftCard?.gift_card_no || ""
    };
    const checkout = useAppSelector((state) => state.checkout);
    const checkoutGiftCards = checkout.giftCard;

    const { authInfo } = useAuthState();
    let { mutateAsync: addGiftCard } = useAddGiftCard();
    let { mutateAsync: updateGiftCard } = useUpdateGiftCard()
    let queryClient = useQueryClient();

    const [isDefault, setIsDefault] = useState<boolean>(editGiftCard?.is_default || false);
    const [isSaveCard, setIsSaveCard] = useState<boolean>(!isCheckout);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleFormSubmission = async (
        values: any,
        { setSubmitting }: any
    ) => {
        if(isCheckout)
        {
            // if duplicate card is being added from checkout page for order payment
            if (checkoutGiftCards.findIndex((card) => card.gift_card_no === values?.gift_card_no) >= 0
                && !editGiftCard
            )
            {
                Toast_Func({status: false, message: "Gift Card already added." });
                return;
            }
        }
        const updatedValues = {
            ...values,
            is_default: isDefault,
            is_saved: isSaveCard
        }
        setIsLoading(true);
        editGiftCard ? handleEditGiftCard(updatedValues) : handleAddGiftCard(updatedValues);
    };

    const handleSuccess = async (data) => {
        if(isCheckout) {
            handleCheckoutNewPaymentSuccess(data, isSaveCard);
        }
        else {
            queryClient.refetchQueries("giftCards");
            queryClient.invalidateQueries(["profile"]);
            handleCloseGiftCard();
        }
        setIsLoading(false);
    }
    const handleEditGiftCard = async (updatedValues) => {
        await updateGiftCard({
            data: {...updatedValues},
            recordID: editGiftCard.id }
            ,{
                onSuccess: (data) => {
                    handleSuccess(data);
                    Toast_Func({ status: true, message: "Gift Card updated Successfully." });
                },
                onError: (error: any) => {
                    setIsLoading(false);
                    Toast_Func({ status: false, message: error?.response?.data?.message });
                }
            })
    }

    const handleAddGiftCard = async (updatedValues) => {
        await addGiftCard(updatedValues,{
            onSuccess: (data) => {
                handleSuccess(data);
                Toast_Func({ status: true, message: "Gift Card added Successfully." });
            },
            onError: (error: any) => {
                setIsLoading(false);
                Toast_Func({ status: false, message: error?.response?.data?.message });
            }
        })
    }

    return (
        <Formik
            initialValues={initialFormState}
            validationSchema={giftCardSchema(isGuestUser(authInfo))}
            onSubmit={handleFormSubmission}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  submitForm,
              }) => (
        <form onSubmit={handleSubmit} className="new_form_design my-0 mx-auto delivery_location_select full_width_mob" >
            {
                authInfo.type !== GUEST_USER ?
                    <div className="form-group">
                        <FormField
                            labelText={"Name Your Gift Card"}
                            name="name"
                            type="text"
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            value={values.name}
                            dataTestId="giftCardName"
                        />
                    </div>
                    :
                    null
            }
            <FormField
                labelText={"Gift Card number"}
                name="gift_card_no"
                type="text"
                onChange={handleChange}
                errors={errors}
                touched={touched}
                value={values.gift_card_no}
                dataTestId="giftCardNumber"
            />

            {
                authInfo.type !== GUEST_USER ?
                    <>
                        {
                           isCheckout &&
                            <InputCheckbox
                                id="save_card"
                                name="save_card"
                                onChange={(e) => {
                                    setIsSaveCard(!isSaveCard)
                                }}
                                checked={isSaveCard}
                                labelText="Save card information to my account"
                            />
                        }
                        <InputCheckbox
                            id="default_payment"
                            name="is_default"
                            onChange={(e) => {
                                setIsDefault(!isDefault)
                            }}
                            checked={isDefault}
                            labelText="Make this my default payment method"
                        />
                    </> : null
            }

            <div className="d-flex justify-content-center my-5">
                <Button type="button" className="btn btn-large py-2 f-s16"
                        id={'add-gift-card-button'} onClick={submitForm}
                        loading={isLoading}
                       
                >
                    { editGiftCard? "Edit Gift Card" : "Add Gift Card"}
                </Button>
            </div>
        </form>
            )}
        </Formik>
    );
};
export default GiftCardForm;