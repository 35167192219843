import * as Yup from "yup";

export const signupSchema = () => Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .matches(
      /^[a-zA-Z ]*$/,
      "First name should not contain special characters or numbers"
    )
    .max(46, "First name cannot be longer than 46 characters"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(
      /^[a-zA-Z ]*$/,
      "Last name should not contain special characters or numbers"
    )
    .max(46, "Last name cannot be longer than 46 characters"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email address is required")    
    .test("no-alias", `The email address must not contain a "+" character`, (value) => {
      if (!value) return true; // Skip if empty (handled by required)
      return !value.includes("+"); // Check for email alias
    })
    .test("no-private-relay", "Please enter a valid email address", (value) => {
      if (!value) return true; // Skip if empty (handled by required)
      return !value.toLowerCase().includes("privaterelay"); // Check for private relay
    }),
  password: Yup.string()
    .min(8, "Password be at least 8 characters long")
    .max(20, "Passowrd length should be less than 20 characters")
    .matches(
      /^(?!.*['"])(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[¡!₩¥€÷×@#$%`°•○●□■♤♡◇♧》¤▪︎☆^&*()\-_=+{}\[\];:,<.>£§~?|¿]).\S*$/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and must not contain spaces or quotation marks."
    )
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password does not match")
    .required("Password confirmation is required"),
  marketing_email_subscription: Yup.boolean(),
  birthday: Yup.string()
    .optional()
    .test("valid-date", "Please enter a valid Birthdate (MM/DD)", (value) =>
      isValidDateInput(value)
    ),
    phone:
    Yup.string()
    .nullable()
    .transform((value) => value.replace(/[^\d]/g, ""))
    .length(10, "Phone number must be 10 digits long")
});


const isValidDateInput = (value: string) => {
  if (value?.length > 0) {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    const [month, day] = value?.split("/").map((s) => parseInt(s, 10));
    const isMonthValid = month >= 1 && month <= 12;
    const isDayValid =
      day >= 1 && day <= new Date(currentYear, month, 0).getDate();
    return isMonthValid && isDayValid;
  }
  return true;
};
