import React, { useEffect } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './App.scss';
import Routes from './containers/routes/Routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TagManager from 'react-gtm-module';
import useCookie from 'react-use-cookie';
import { AuthProvider } from './context/UserAuthentication';
import { LocationProvider } from './context/StoreLocation';
import ScrollToTop from './components/ScrollToTop';
import { setRequestId, setVisitorId } from './helpers/helperMethods';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { logErrorMessage } from './helpers/toast.helper';
import Authentication from './components/Authentication/Authentication';
import Geocode from 'react-geocode';
import {
  GOOGLE_API_KEY,
  ROUTE_CATERING,
  CATERING_CAFE_ZUPAS_URL,
} from './constants';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { brazeInititalize } from './helpers/brazeHelper';
import LogOutAction from './components/Authentication/LogOutAction';
import { HandleFallBackUrlsProvider } from './context/HandleFallBackUrlsContext';
import Bugsnag from "@bugsnag/js";

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage('en');

brazeInititalize();

// rewardCatalog "Reward catalog could not be fetched",
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: any, query: any) => {
      const queryKey = query.queryKey[0];
      logErrorMessage(queryKey);
    },
  }),

  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

/*This is an aggressive fix because of update in react/types verison 18*/
declare module 'react-query/types/react/QueryClientProvider' {
  export interface QueryClientProviderProps {
    children?: React.ReactNode;
  }
}

function App() {
  const publishableKey = process.env.REACT_APP_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(publishableKey);
  const path = new URL(window.location.href).pathname;

  const [cookies, setCookie] = useCookie('EmailModal');

  setVisitorId();
  setRequestId();

  useEffect(() => {
    /**
     * CafeZupas GTM Tag
     */
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM });

    /**
     * Max Connect Marketing Media GTM Tag
     */
    TagManager.initialize({
      gtmId: process.env.REACT_APP_MAX_CONNECT_MARKETING_MEDIA_GTM,
    });

    if (cookies !== 'true') {
      document.querySelectorAll(`#root`).forEach(function (e) {
        if (e.hasAttribute('setBy')) {
          e.setAttribute('setBy', 'APP');
        }
        if (e.hasAttribute('inert')) {
          e.setAttribute('inert', '');
        }
      });
    }

    if (
      process.env.REACT_APP_ENABLE_COOKIEYES === 'true' &&
      process.env.REACT_APP_COOKIEYES_SITEID
    ) {
      window.addEventListener('load', function (e) {
        initCookiesConsent();
      });
    }

    function initCookiesConsent() {
      const cookiesScript = document.createElement('script');
      let scriptPlacementExecutionCount = 0;
      let anchorTargetRemovalExecutionCount = 0;

      cookiesScript.id = 'cookieyes';
      cookiesScript.src = `https://cdn-cookieyes.com/client_data/${process.env.REACT_APP_COOKIEYES_SITEID}/script.js`;

      let scriptPlacementInterval = setInterval(() => {
        const firstChildEle = document.head.children[0] as HTMLInputElement;
        document.head.insertBefore(cookiesScript, firstChildEle);

        if (
          firstChildEle.getAttribute('id') === 'cookieyes' &&
          scriptPlacementExecutionCount === 4
        ) {
          clearInterval(scriptPlacementInterval);
          let anchorTargetRemovalInterval = setInterval(() => {
            const ele = document.querySelector(
              '.cky-consent-container .cky-policy'
            );

            if (ele) {
              ele.removeAttribute('target');
            } else {
              if (anchorTargetRemovalExecutionCount === 9) {
                clearInterval(anchorTargetRemovalInterval);
              }
            }

            anchorTargetRemovalExecutionCount++;
          }, 1000);
        }

        scriptPlacementExecutionCount++;
      }, 1000);
    }
  }, [cookies]);

  if (path === ROUTE_CATERING) {
    window.location.href = CATERING_CAFE_ZUPAS_URL;
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate loading={<>loading...</>} persistor={persistor}>
        <div className="App">
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_SIGNIN_ID}
          >
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <AuthProvider>
                    <Elements stripe={stripePromise}>
                      <LocationProvider>
                        <ScrollToTop>
                        <HandleFallBackUrlsProvider>
                          <Authentication>
                            <LogOutAction />
                            <Routes />
                          </Authentication>
                          </HandleFallBackUrlsProvider>
                        </ScrollToTop>
                      </LocationProvider>
                    </Elements>
                  </AuthProvider>
                </BrowserRouter>
                <ToastContainer />
                <ReactQueryDevtools />
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
