import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must Enter Valid Email")
    .required("Must Enter Email Address"),
  password: Yup.string()
  .matches(
    /^(?!.*['"]).\S*$/,
    "Password must not contain spaces or quotation marks."
  )
  .required("Must Enter Password"),
});

export const emailConfirmationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must Enter Valid Email")
    .required("Must Enter Email Address")
    .test("no-alias", `The email address must not contain a "+" character`, (value) => {
      if (!value) return true; // Skip if empty (handled by required)
      return !value.includes("+"); // Check for email alias
    })
    .test("no-private-relay", "Please enter a valid email address", (value) => {
      if (!value) return true; // Skip if empty (handled by required)
      return !value.toLowerCase().includes("privaterelay"); // Check for private relay
    }),
  confirm_email: Yup.string()
    .email("Must Enter Valid Email")
    .required("Must Enter Email Address")
    .oneOf([Yup.ref("email"), null], "The email addresses do not match")
});