// @flow
import * as React from "react";
import { REACT_APP_CLOUD_FRONT } from "../../constants";
import styleClasses from "../../containers/order/order.module.scss";
import Image from "../Image/Image";

interface Props {
  handleClick: () => void;
  label?: string
}
export default function BackButton(props: Props) {
  const { handleClick, label= 'back' } = props;
  return (
    <button type="button"
    onClick={handleClick}
    data-testid="back-button"
    className="btn btn-custom back-arrow-btn f-s14 px-0 mb-0 d-flex align-items-center lh-normal"
  >
    {label}
  </button>
  );
}
