import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { getUser, getVisitorId, isGuestUser } from '../helpers/helperMethods';
import useCheckMobileScreen from './useCheckMobileScreen';
import useTriggerCampaign from '../react-query-hooks/useTriggerCampaign';
import { Toast_Func } from '../helpers/toast.helper';
import {
  CURRENT_USER,
  DELIVERY_DESTINATION_ID,
  DELIVERY_ORDER_TYPE,
  GUEST_USER,
  IN_STORE_DESTINATION_ID,
  ROUTE_LOGIN,
} from '../constants';
import { socialSignIn, useAuthState } from '../context/UserAuthentication';
import { useDispatch } from 'react-redux';
import { setLocation, useStoreLocation } from '../context/StoreLocation';
import { brazeLogCustomEventLoyaltyAccountCreated } from '../helpers/brazeHelper';
import { useAppSelector } from '../redux/store/store';

const UPDATE_EMAIL = 'update_email';
const ISSUE_OFFER = 'issue_offer';

const useHandleFallBackUrls = () => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);

  const action = searchParams.get('action');
  const utm_source = searchParams.get('utm_source');

  const { dispatch, authInfo } = useAuthState();
  const cartDispatch = useDispatch();
  const { dispatch: locationDispatch, locationInfo } = useStoreLocation();

  const location_id = locationInfo.selectedStore?.id;

  const [params, setParams] = useState({});
  const [campaginAction, setCampaginAction] = useState(action);
  const [loading, setLoading] = useState<boolean>(false);

  const cart = useAppSelector((state) => state.cart);

  const user = getUser();

  const isThisMobile = useCheckMobileScreen();

  const { data: userOfferResponse, isFetching } = useTriggerCampaign(
    {
      ...params,
      logged_in_user: !isGuestUser(user) ? user?.encoded_cz_id : null,
      source: isThisMobile ? 'Mobile Web' : 'Web',
    },
    Boolean(action && Object.keys(params).length)
  );

  useEffect(() => {
    if (!action) return;
    let paramsObject = {};
    // Iterate through all parameters and store them in an object
    searchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });
    if (action === UPDATE_EMAIL) {
      setParams({
        external_email: paramsObject['external_email'],
        action: paramsObject['action'],
        is_email_verified: paramsObject['is_email_verified'],
        loyalty_user_id: paramsObject['loyalty_user_id'],
        encoded_cz_id: paramsObject['encoded_cz_id'],
        source: paramsObject['source'],
      });
    }
    if (action === ISSUE_OFFER) {
      if(authInfo.type === GUEST_USER){
        paramsObject = {...paramsObject, logged_in_user: null}
      }else{
        paramsObject = {...paramsObject, logged_in_user: user?.encoded_cz_id}
      }
      setParams(paramsObject);
    }
  }, []);

  useEffect(() => {
    if (!action) return;
    if (userOfferResponse) {
      searchParams.delete('action');
      // Replace the URL with the updated one (without reloading the page)
      history.replace(
        {
          pathname: location.pathname, // Keep the path the same
          search: searchParams.toString(), // Empty string means no query params
        },
        { replace: true }
      );
    }
    if (campaginAction === ISSUE_OFFER) {
      runIssueOfferCases();
    }
    if (campaginAction === UPDATE_EMAIL) {
      runUpdateEmailCases();
    }
  }, [userOfferResponse]);

  useEffect(() => {
    if (utm_source == 'apple_link') {
      const paramsObject: any = {};
      // Iterate through all parameters and store them in an object
      searchParams.forEach((value, key) => {
        paramsObject[key] = value;
      });

      //Making keys on frontend side, as backend denying to correct the redirect url
      const newPayload = {
        email: paramsObject?.email,
        apple_id: paramsObject?.apple_id,
        apple_verified: 1,
        id_token: paramsObject?.['payload[id_token]'],
        code: paramsObject?.['payload[code]'],
        channel: paramsObject?.['payload[channel]'],
        type: paramsObject?.type,
      };
      searchParams.delete('utm_source');
      // Replace the URL with the updated one (without reloading the page)
      history.replace(
        {
          pathname: location.pathname, // Keep the path the same
          search: searchParams.toString(), // Empty string means no query params
        },
        { replace: true }
      );

      appleSignInOnRedirectUrlInCaseOfUtmSourceKey(newPayload);
    }
  }, [utm_source]);

  const signInUserWithoutNewAPICall = (response: any): void => {
    dispatch({ type: 'LOGIN_SUCCESS', payload: response });
    localStorage.setItem(
      CURRENT_USER,
      JSON.stringify({ ...response, visitor_id: getVisitorId() })
    );
    if (response.is_new_user)
      brazeLogCustomEventLoyaltyAccountCreated(response.data.data);
  };

  const runIssueOfferCases = () => {
    if (userOfferResponse?.code === 200 && userOfferResponse.show_toast) {
      Toast_Func({ status: true, message: userOfferResponse.message });
      setCampaginAction(null);
    }
    if (
      userOfferResponse &&
      isGuestUser(user) &&
      !userOfferResponse?.customer
    ) {
      history.push(ROUTE_LOGIN);
      setCampaginAction(null);
    }
  };

  const runUpdateEmailCases = () => {
    if (userOfferResponse?.code === 200) {
      Toast_Func({ status: true, message: userOfferResponse.message });
      setCampaginAction(null);
    }
    if (userOfferResponse && userOfferResponse?.customer) {
      signInUserWithoutNewAPICall(userOfferResponse.customer);
      setCampaginAction(null);
    }
  };

  const appleSignInOnRedirectUrlInCaseOfUtmSourceKey = async (payload) => {
    let orderType;
    if (cart?.orderType) {
      orderType =
      cart?.orderType === DELIVERY_ORDER_TYPE
          ? DELIVERY_DESTINATION_ID
          : IN_STORE_DESTINATION_ID;
    }
    try {
      setLoading(true);
      await socialSignIn(
        dispatch,
        payload,
        'APPLE_SIGNIN',
        cartDispatch,
        (selectedStore) => {
          setLocation(locationDispatch, selectedStore);
        },
        false,
        location_id,
        orderType
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Toast_Func({ status: false, message: 'Please try again' });
    }
  };

  return { data: userOfferResponse, loading: loading || isFetching };
};

export default useHandleFallBackUrls;
