import { updateCartOrder } from "../../redux/slices/cartSlice";
import { CURRENT_USER } from "../../constants";
import { getCart } from "../../react-query-hooks/Cart/useGetCartItems";

export const syncCartOnLogin = async (cartDispatch, locationId = null, orderType = null) => {
  let payload: any = { customer_id:JSON.parse(localStorage.getItem(CURRENT_USER)).id }
  if(locationId){
        payload = {...payload, location_id:locationId}
  }
  if(locationId && orderType){
    payload = {...payload, order_type:orderType}

  }
  const response = await getCart(payload)
  if( response && response.items ) cartDispatch(updateCartOrder(response));
}