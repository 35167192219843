import React from 'react'
import { Modal } from 'react-bootstrap';
import { APPLE_SIGNIN, CUSTOMER_MICRO_SERVICE, EMAIL_SENT_AGAIN, RESEND_EMAIL_VERIFICATION } from '../../../constants';
import axios from 'axios';
import BackButton from '../../BackButton';
import Button from '../../Buttons/Button';

export default function ResendEmailModal(props) {
    const {
        setResendEmail,
        resendEmail,
        setShowValidateEmail,
        resendEmailPayload,
        sendingEmail,
        setSendingEmail,
        setResendEmailData,
        resendEmailData,
        isItMobile,
        setResendEmailBtnText,
        resendEmailBtnText,
        signInUserWithoutNewAPICall,
        addDarkerOverlay = false
      } = props;
    
      const formatBody2Text = (text) => {
        if (!text) return null;
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
        return text.split('\n').map((line, index) => {
          const match = line.match(emailRegex);
          if (match) {
            const parts = line.split(match[0]);
            return (
              <div key={index}>
                {parts[0]}
                  <span className="clr-dark-blue">{match[0]}</span>
                {parts[1]}
              </div>
            );
          }
          return <div key={index}>{line}</div>;
        });
      };
    
      const handleResendEmail = async () => {
        try {
          setSendingEmail(true);
          const response = await axios.post(
            `${CUSTOMER_MICRO_SERVICE}${APPLE_SIGNIN}`,
            resendEmailPayload
          );
          if (!response?.data?.data?.is_email_sent) {
            signInUserWithoutNewAPICall(response);
          } else if (response.data) {
          setSendingEmail(false);
          setResendEmailBtnText(EMAIL_SENT_AGAIN);
          }
        } catch (error) {
          setSendingEmail(false);
        }
      };
    
      const closeModal = () => {
        setResendEmail(false);
        setResendEmailData(null);
        setResendEmailBtnText(RESEND_EMAIL_VERIFICATION);
      };
      return (
        <Modal
          show={resendEmail}
          centered
          className="theme-modal-style app_container_wrap  border-radius-15 px-5 order_type_modal"
          onHide={closeModal}
          size="sm"
        style={addDarkerOverlay ? {background: "#00000088"} : {}}
        >
          <Modal.Header className="x-4 justify-content-between">
            <BackButton
              handleClick={() => {
                setResendEmail(false);
                setShowValidateEmail(true);
                setResendEmailData(null);
                setResendEmailBtnText(RESEND_EMAIL_VERIFICATION);
              }}
              label="Change Email Address"
            />
            <button
              type="button"
              className="btn modal-close-button pe-0 sm-blue-icon"
              onClick={closeModal}
            >
              Close
            </button>
          </Modal.Header>
          <Modal.Body className="px-3 gap-40">
            <>
              <h5
                className="f-s20 font-Cls text-center fw-normal lh-normal mb-4 text-capitalize"
                style={{ whiteSpace: 'pre-line' }}
              >
                {`${resendEmailData?.header}`}
              </h5>
              <div
                className="text_center"
                style={isItMobile ? {} : { marginInline: '2.5rem' }}
              >
                <div className="mt-2 text-center mb-4 font-Visby-cf">
                  <span className="fw-bolder" style={{ whiteSpace: 'pre-line' }}>
                  {formatBody2Text(resendEmailData?.body1)}
                  </span>
                </div>
                <div className="mt-2 text-center mb-5 font-rale">
                  <span style={{ whiteSpace: 'pre-line' }}>
                    {resendEmailData?.body2}
                  </span>
                </div>
                <Button
                  type="button"
                  className="btn btn-large mb-sm-3 mb-3 mt-sm-3 mt-md-4 d-block w-100"
                  onClick={handleResendEmail}
                  disabled={sendingEmail || resendEmailBtnText === EMAIL_SENT_AGAIN}
                  loading={sendingEmail}
                >
                  {resendEmailBtnText}
                </Button>
              </div>
            </>
          </Modal.Body>
        </Modal>
      );
}
